import React from 'react';

const EmailIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#6B71FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5454 10.6666H21.4545C22.124 10.6666 22.6666 11.1973 22.6666 11.8518V20.1481C22.6666 20.8027 22.124 21.3333 21.4545 21.3333H10.5454C9.876 21.3333 9.33331 20.8027 9.33331 20.1481V11.8518C9.33331 11.1973 9.876 10.6666 10.5454 10.6666ZM10.5456 14.5883V20.1481H21.4546V14.5887L16.0003 17.2553L10.5456 14.5883ZM10.5456 13.2632L16.0003 15.9302L21.4546 13.2637V11.8518H10.5456V13.2632Z"
      fill="white"
    />
  </svg>
);

export default EmailIcon;
