import React, { useEffect } from 'react';
import styled from 'styled-components';
import { navigate, PageProps } from 'gatsby';

import SuccessIcon from '@/components/generics/svg/successIcon';
import EmailIcon from '@/components/generics/svg/emailIcon';
import { BenefitsText, Spacer } from '@/components/generics/styled';
import DownloadLinks from '@/components/home/downloadLinks';
import Footer from '@/components/home/footer';
import Header from '@/components/header';
import Layout from '@/components/layout';

type SuccessLocState = {
  email: string;
  phone: string;
  medidor: string;
};

const MainWrapper = styled.main`
  background: #fefefe;
  padding: 50px 16px 0px;
`;

const WidthWrapper = styled.div`
  @media (min-width: 768px) {
    max-width: 500px;
    margin: auto;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  height: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f6fa;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: auto;
`;

const WrappedIcon = styled.div`
  margin: 0 0 8px;
  width: 32px;
  height: 32px;
`;

const DataText = styled.span`
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;

  color: #002350;
`;

const DataTitle = styled(DataText)`
  font-weight: 600;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 45px;
`;

const SuccessMessage: React.FC<Partial<SuccessLocState>> = ({ medidor }) => (
  <MessageWrapper>
    <SuccessIcon />
    <Spacer space={16} />
    <DataTitle>Recargaste $50 en tu medidor prepago</DataTitle>
    <Spacer space={24} />
    <DataText>{`Medidor: ${medidor}`}</DataText>
  </MessageWrapper>
);

const SuccessBody: React.FC<SuccessLocState> = ({ email, medidor }) => (
  <Wrapper>
    <SuccessMessage medidor={medidor} />
    <InfoWrapper>
      <WrappedIcon>
        <EmailIcon />
      </WrappedIcon>
      <DataText>Te enviamos el código de recarga a&nbsp;</DataText>
      <DataTitle>{email}</DataTitle>
    </InfoWrapper>
  </Wrapper>
);

const Success: React.FC<PageProps<null, null, SuccessLocState>> = ({
  location,
}) => {
  useEffect(() => {
    if (
      !location.state?.email ||
      !location.state?.phone ||
      !location.state?.medidor
    ) {
      navigate('/');
    }
  }, []);
  if (
    !location.state?.email ||
    !location.state?.phone ||
    !location.state?.medidor
  ) {
    return null;
  }
  const { email, phone, medidor } = location.state;

  return (
    <Layout>
      <Header />
      <WidthWrapper>
        <SuccessBody email={email} phone={phone} medidor={medidor} />
      </WidthWrapper>
      <MainWrapper>
        <WidthWrapper>
          <BenefitsText>
            Descargá Tap y ganá otros $400 en tu próxima recarga
          </BenefitsText>
          <DownloadLinks />
        </WidthWrapper>
      </MainWrapper>
      <Footer />
    </Layout>
  );
};

export default Success;
