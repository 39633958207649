import React from 'react';

const SuccessIcon: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C37.2548 0 48 10.7453 48 24.0001C48 37.2549 37.2548 48 24 48C10.7452 48 0 37.2549 0 24.0001C0 10.7453 10.7452 0 24 0Z"
      fill="#14D2B9"
    />
    <path
      d="M20.0858 32.7451L12.1543 24.8116C11.6777 24.335 11.6777 23.5623 12.1543 23.0858L13.8801 21.36C14.3566 20.8834 15.1292 20.8834 15.6058 21.36L20.9487 26.7033L32.3935 15.2589C32.8701 14.7824 33.6427 14.7824 34.1193 15.2589L35.8453 16.9846C36.3218 17.4612 36.3218 18.2337 35.8453 18.7103L21.8116 32.7451C21.335 33.2215 20.5624 33.2215 20.0858 32.7451Z"
      fill="#FEFEFF"
    />
  </svg>
);

export default SuccessIcon;
